@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/Helvetica-Regular.woff');
    font-weight: normal;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/Helvetica-Medium.woff');
    font-weight: 500;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./fonts/Helvetica-Thin.woff');
    font-weight: 100;
}

body {
    font-family: Helvetica;
}

html, body {
    padding: 0;
    margin: 0;
}

*::-webkit-scrollbar {
    width: 3px;
}

*::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-thumb {
    background-color: #6184AD;
}
